import { addIncompleteSurvey } from '../utils/incompleteSurveys';
import { all, call, cancel, fork, put, race, select, take } from 'redux-saga/effects';
import { API_URL } from '../../../config';
import {
  applyTrigger,
  removeAppliedTrigger,
  setFirstResponseTime,
  setIsSubmitting,
  setQuestion,
  updateElement,
} from '../actions';
import { CHAT, THANK } from '../../FlowState/stateTypes';
import { END, eventChannel } from 'redux-saga';
import { maskPersonalData } from '../utils/hidePersonalData';
import { NEXT_PAGE, SUBMIT } from '../../../components/FlowStateElement/elementTypes';
import { nextStateSaga, isQuestionsAnswered, findNextStateInfo } from './changeStateSaga';
import { REMOVE_FILE, TRIGGER_MAKE_INVISIBLE, TRIGGER_MAKE_VISIBLE } from '../constants';
import { ReportedError, createError, shouldReport } from '../../../utils/error';
import { requestJson } from '../../../utils/request';
import { scrollToTargetElement } from '../helpers/scrollHelper';
import { SentryReport } from '../../../utils/sentry';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import findKey from 'lodash/findKey';
import flatMap from 'lodash/flatMap';
import formatBytes from '../../../utils/formatBytes';
import getFeedbackFromState from '../utils/getFeedbackFromState';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import sendFeedback from '../utils/sendFeedback';

function getTriggers(state, questionId, response) {
  const element = state.flow.elements[questionId];
  const responseArray = [].concat(response).filter((el) => !!el);
  if (!responseArray.length || element.triggers == null || element.triggers.length === 0) {
    return [];
  }
  const selected = [];
  return flatMap(responseArray, (response) => {
    let triggers = [];
    if (element.question.style === 'matrix') {
      // Since the question's options and the statement's options have different ID we need to find
      // question's option to match it with the trigger's option
      const tempStatement = find(element.question.statements, (el) => el.id === response.statementId);
      selected.push({
        statement: tempStatement.key,
        option:
          element.question.options[findIndex(tempStatement.options, (el) => el.id === response.statementOptionId)].key,
      });
      triggers = element.triggers.filter(filterTriggers, selected);
    } else {
      const selectedOption = find(element.question.options, { id: response });
      triggers = element.triggers.filter((trigger) => trigger.key === selectedOption.key);
    }
    if (!triggers.length) {
      return null;
    }
    return map(triggers, (trigger) => {
      const targetIds = map(trigger.targetIds, (targetId) => findKey(state.flow.elements, { key: targetId }));
      return { targets: targetIds, action: trigger.action };
    });
  }).filter((el) => !!el);
}

function filterTriggers(trigger) {
  return trigger.statementKey
    .map((key) => {
      const matchedStatement = this.filter((el) => el.statement === key);
      return matchedStatement.length > 0 && matchedStatement[0].option === trigger.key;
    })
    .every(Boolean);
}

function hasStateNextPageButton(state) {
  const { currentState, elements } = state.flow;
  return (
    currentState.elements
      .map((id) => elements[id])
      .filter((el) => (el.type === NEXT_PAGE || el.type === SUBMIT) && el.isVisible).length > 0
  );
}

export function* removeAppliedTriggers(state, questionId, response = null) {
  let appliedTriggers = filter(state.flow.appliedTriggers, {
    sourceElementId: questionId,
  });

  if (response && Array.isArray(response) && response.length > 0) {
    const hasTriggers = response.some((r) => getTriggers(state, questionId, r).length > 0);
    appliedTriggers = hasTriggers ? [] : appliedTriggers;
  }

  if (appliedTriggers.length > 0) {
    for (let i = 0; i < appliedTriggers.length; i += 1) {
      const appliedTrigger = appliedTriggers[i];
      yield put(removeAppliedTrigger(appliedTrigger));
      switch (appliedTrigger.action) {
        case TRIGGER_MAKE_VISIBLE:
          yield put(
            updateElement({
              elementId: appliedTrigger.targetElementId,
              isVisible: false,
            }),
          );

          // We only need to change the response if the target element is a question.
          const isQuestion = state.flow.questions[appliedTrigger.targetElementId];

          if (isQuestion) {
            yield call(changeResponseSaga, {
              payload: {
                questionId: appliedTrigger.targetElementId,
                newResponseParams: {
                  response: null,
                  isValid: true,
                },
              },
            });
          }
          break;
        case TRIGGER_MAKE_INVISIBLE:
          yield put(
            updateElement({
              elementId: appliedTrigger.targetElementId,
              isVisible: true,
            }),
          );
          break;
        default:
          throw createError(ReportedError, 'systemError', `Unexpected trigger type: ${appliedTrigger.action}`);
      }
    }
  }
}

function* applyTriggers(state, questionId, response, globalStateElements) {
  let targetPositionId = null;
  let targetPositionOrder = null;
  const triggers = getTriggers(state, questionId, response);
  // Basic for used for calling yield, yield not allowed in fat arrow functions
  for (let j = 0; j < triggers.length; j++) {
    const trigger = triggers[j];
    for (let i = 0; i < trigger.targets.length; i += 1) {
      const targetElementId = trigger.targets[i];
      if (isNil(targetElementId)) break; // TODO trigger target not in elements
      const triggerPayload = {
        sourceElementId: questionId,
        targetElementId,
        action: trigger.action,
      };
      switch (trigger.action) {
        case TRIGGER_MAKE_VISIBLE:
          if (state.flow.elements[targetElementId].isVisible) break;
          yield put(applyTrigger(triggerPayload));
          yield put(updateElement({ elementId: targetElementId, isVisible: true }));

          try {
            // global elementleri kullanmak durumundayız çünkü isVisible gerçek değeri parametreden gelen state değil
            if (
              (globalStateElements && !globalStateElements[targetElementId].isVisible && targetPositionOrder == null) ||
              targetPositionOrder > state.flow.elements[targetElementId].order
            ) {
              targetPositionId = targetElementId;
              targetPositionOrder = state.flow.elements[targetElementId].order;
            }
          } catch (error) {
            if (shouldReport(error)) {
              SentryReport({ error });
            }
          }

          break;
        case TRIGGER_MAKE_INVISIBLE:
          if (!state.flow.elements[targetElementId].isVisible) break;
          yield put(applyTrigger(triggerPayload));
          yield put(updateElement({ elementId: targetElementId, isVisible: false }));
          break;
        default:
          throw createError(ReportedError, 'systemError', `Unexpected trigger type: ${trigger.action}`);
      }
    }
  }
  if (targetPositionId) {
    setTimeout(() => scrollToTargetElement(targetPositionId), 400);
  }
}

export function* setFirstResponseTimeIfRequired(state) {
  const { firstResponseTime } = state.flow;
  if (!firstResponseTime) {
    yield put(setFirstResponseTime(Date.now()));
  }
}

function* saveIncompleteSurvey(state) {
  let response = {};
  let body = {};

  try {
    const feedback = getFeedbackFromState(state, true);

    if (!feedback.responses.length) {
      return;
    }

    maskPersonalData(feedback);

    response = yield call(sendFeedback, feedback, true, true);
    body = JSON.parse(response.responseText);

    const { id } = body;
    const { id: flowId, nodeId } = state.flow;

    addIncompleteSurvey(id, nodeId, flowId);
  } catch (error) {
    if (shouldReport(error)) {
      SentryReport({ error });
    }
  }
}

export function* changeResponseSaga({
  payload: {
    questionId,
    newResponseParams: { response, isValid = true, optionCount, preloaded, isPreloaded = false, ...others },
  },
}) {
  let state = yield select();
  // Burada global state üzerinden elementleri alıyoruz. Çünkü state.flow.elements üzerinden alırsak
  // triggerlar çalışmadan önce elementlerin triggerlarını kaldırıyor. isVisible false oluyor.
  const { elements } = state.flow;
  const question = state.flow.questions[questionId];
  const { isVisible } = state.flow.elements[questionId];

  const setQuestionPayload = {
    questionId,
    response,
    isValid,
    hasRequiredError:
      question.isRequired &&
      (!response ||
        (Array.isArray(response) && response.length === 0) ||
        (Array.isArray(response) && optionCount && response.length < optionCount)),
    ...others,
  };
  yield put(setQuestion(setQuestionPayload));
  yield setFirstResponseTimeIfRequired(state);
  // Remove the applied triggers with the old response.
  // Remove triggers only if the triggers response is not selected for multiple questions
  yield removeAppliedTriggers(state, questionId, response);
  state = yield select();
  // Apply the new triggers.
  // Apply triggers according to the last response in case of multiple responses.
  const responseToSend =
    !isPreloaded && Array.isArray(response) && response.length > 0 ? response[response.length - 1] : null;
  yield applyTriggers(state, questionId, responseToSend || response, elements);
  state = yield select();

  if (!hasStateNextPageButton(state) && isVisible) {
    yield nextStateSaga({
      payload: {
        stateChangeParams: {
          source: 'question_saga',
          hasNextStateButton: false,
        },
      },
    });
  }

  if (state.flow.allowSavingIncompleteSurvey) {
    const nextState = findNextStateInfo(state);
    const isNextStateThankOrChat = nextState.style === THANK || nextState.style === CHAT;

    // Save incomplete survey if allowed and if it is not a preloaded response.
    // If it is last state and there is no next page button, make sure all questions are not answered in last state.
    const shouldSaveIncompleteSurvey =
      !preloaded &&
      (!hasStateNextPageButton(state) && isNextStateThankOrChat
        ? !isQuestionsAnswered(state, hasStateNextPageButton(state))
        : true);

    if (shouldSaveIncompleteSurvey) {
      yield saveIncompleteSurvey(state);
    }
  }
}

function uploadAttachmentEmitter(uploadUrl, file) {
  const data = new FormData();
  data.append('file', file);

  // Create an emitter to emit data on progress.
  let emit;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });

  let lastEmittedProgress = -1;
  const options = {
    method: 'POST',
    body: data,
    progress: (e) => {
      const progress = Math.round((e.loaded / e.total) * 100);
      if (progress !== lastEmittedProgress) {
        lastEmittedProgress = progress;
        emit(progress);
      }
    },
  };

  const promise = requestJson(uploadUrl, options).finally(() => emit(END));

  return { promise, chan };
}

function* progressListener(questionId, channel) {
  while (true) {
    const progress = yield take(channel);
    yield put(setQuestion({ questionId, progress }));
  }
}

export function* uploadAttachmentSaga({ payload: { questionId, file } }) {
  yield put(setIsSubmitting(true));

  yield put(
    setQuestion({
      questionId,
      isLoading: true,
      file: { name: file.name, size: formatBytes(file.size), type: file.type },
    }),
  );
  try {
    const { promise: firstPromise, chan: firstChan } = uploadAttachmentEmitter(`${API_URL}/v1/attachments`, file);
    const { promise: secondPromise, chan: secondChan } = uploadAttachmentEmitter(
      `${API_URL}/v2/storage/direct_uploads`,
      file,
    );
    const firstProgressTask = yield fork(progressListener, questionId, firstChan);
    const secondProgressTask = yield fork(progressListener, questionId, secondChan);

    const {
      removeFileAction,
      uploadResults: { uploadedFileAttachment, uploadedFileActiveStorage = { data: {} } },
    } = yield race({
      removeFileAction: take(REMOVE_FILE),
      uploadResults: all({
        uploadedFileAttachment: call(identity, firstPromise),
        uploadedFileActiveStorage: call(identity, secondPromise),
      }),
    });

    if (removeFileAction) {
      // User clicked on the remove button while we are uploading, abort.
      yield cancel(firstProgressTask);
      yield cancel(secondProgressTask);
    } else {
      yield call(changeResponseSaga, {
        payload: {
          questionId,
          newResponseParams: {
            response: uploadedFileAttachment.file_url || uploadedFileActiveStorage.data.url,
            attachmentId: uploadedFileAttachment.id,
            active_storage_blob_id: uploadedFileActiveStorage.data.id,
            isLoading: false,
            isValid: true,
          },
        },
      });
    }
  } catch (error) {
    yield call(changeResponseSaga, {
      payload: {
        questionId,
        newResponseParams: {
          error,
          isLoading: false,
          isValid: false,
        },
      },
    });
  }
  yield put(setIsSubmitting(false));
}

export function* setPhoneVerificationDataSaga({ payload: { questionId, isValid, phoneNumber } }) {
  yield put(
    setQuestion({
      questionId,
      isValid,
      phoneNumber,
    }),
  );
}

export function* sendVerificationTokenSaga({ payload: { verificationParams } }) {
  if (!verificationParams.isValid || !verificationParams.address) {
    yield put(
      setQuestion({
        questionId: verificationParams.questionId,
        isValid: false,
      }),
    );
  } else {
    yield put(setQuestion({ questionId: verificationParams.questionId, isLoading: true }));
    const state = yield select();
    const questionId = state.flow.elements[verificationParams.questionId].question.id;
    const smsUrl = `${API_URL}/v1/customers/send_verification_token`;
    const data = new FormData();
    data.append('address', verificationParams.address);
    data.append('kind', 'sms');
    data.append('question_id', questionId);
    const options = {
      method: 'POST',
      body: data,
    };
    try {
      const verification = yield call(requestJson, smsUrl, options);
      yield put(
        setQuestion({
          questionId: verificationParams.questionId,
          hasRequiredError: false,
          isValid: true,
          isLoading: false,
          phoneNumber: {
            ...state.flow.questions[verificationParams.questionId].phoneNumber,
            verificationId: verification.id,
          },
        }),
      );
    } catch (error) {
      yield put(setQuestion({ questionId: verificationParams.questionId, isLoading: false, error }));
    }
  }
}

export function* verifyPhoneNumberSaga({ payload: { verifyParams } }) {
  if (!verifyParams.token) {
    yield put(
      setQuestion({
        questionId: verifyParams.questionId,
        hasRequiredError: true,
      }),
    );
  } else {
    yield put(setQuestion({ questionId: verifyParams.questionId, isLoading: true }));
    const verificationUrl = `${API_URL}/v1/customers/verify`;
    const data = new FormData();
    data.append('verification_id', verifyParams.verificationId);
    data.append('token', verifyParams.token);
    const options = {
      method: 'POST',
      body: data,
    };
    try {
      const verificationData = yield call(requestJson, verificationUrl, options);
      yield put(
        setQuestion({
          questionId: verifyParams.questionId,
          response: verificationData.address,
          token: verificationData.token,
          isValid: true,
          isLoading: false,
          hasRequiredError: false,
        }),
      );
    } catch (error) {
      yield put(
        setQuestion({
          questionId: verifyParams.questionId,
          isValid: false,
          isLoading: false,
          hasRequiredError: false,
          error,
        }),
      );
    }
  }
}

export function* doDynamicRequestSaga({ payload: { questionId, answer } }) {
  yield put(
    setQuestion({
      questionId,
      response: answer,
      isLoading: true,
    }),
  );
  const state = yield select();
  const questionIdConst = state.flow.elements[questionId].question.id;
  const dynamicRequestUrl = `${API_URL}/v1/flows/${state.flow.id}/requests`;
  const data = {
    question_id: questionIdConst,
    flow_id: state.flow.id,
    answer,
  };
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  try {
    const result = yield call(requestJson, dynamicRequestUrl, options);
    yield put(setQuestion({ questionId, isLoading: false }));
    for (let i = 0; i < result.responses.length; i++) {
      const response = result.responses[i];
      const id = findKey(state.flow.elements, {
        question: {
          id: response.question_id,
        },
      });
      yield put(setQuestion({ questionId: id, response: response.input }));
    }
  } catch (error) {
    yield put(
      setQuestion({
        questionId,
        error,
        isLoading: false,
      }),
    );
  }
}
